/* Navbar.css */

.hover-item {
    position: relative;
    display: inline-block;
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color and scaling */
  }
  
  .hover-item:hover { 
    transform: scale(1.1); /* Slightly increase size on hover */
  }
  
  /* Optional: Add a cool underline effect with pseudo-elements */
  .hover-item::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0); /* Initially hidden (scaleX to 0) */
    height: 2px; /* Thickness of the underline */
    bottom: 30px; /* Position underline below the text */
    left: 0; /* Align underline with the left of the text */
    background-color: #888888; /* Color of the underline */
    transform-origin: bottom right; /* Start the animation from the bottom right */
    transition: transform 0.3s ease-out; /* Smooth transition for the underline effect */
  }
  
  .hover-item:hover::after {
    transform: scaleX(1); /* Scale underline to full width on hover */
    transform-origin: bottom left; /* Animation starts from the bottom left on hover */
  }

  .active {
    font-weight: bold; /* Make the active link bold */
  }
  
  .active::after {
    transform: scaleX(1); /* Show the underline for the active link */
    transform-origin: bottom left;
  }

